import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from '../lib/i18n/TranslationContext';
import { 
  AutoAwesome,
  Schedule,
  Chat,
  AccountTree,
  Psychology,
  Autorenew,
  SmartToy,
  Notifications
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function AiLanding() {
  const { t } = useTranslation();

  const features = [
    {
      title: t('ai.features.list.taskManagement.title'),
      description: t('ai.features.list.taskManagement.description'),
      icon: Schedule,
      color: "bg-violet-500"
    },
    {
      title: t('ai.features.list.communication.title'),
      description: t('ai.features.list.communication.description'),
      icon: Chat,
      color: "bg-blue-500"
    },
    {
      title: t('ai.features.list.automation.title'),
      description: t('ai.features.list.automation.description'),
      icon: AccountTree,
      color: "bg-green-500"
    },
    {
      title: t('ai.features.list.documents.title'),
      description: t('ai.features.list.documents.description'),
      icon: AutoAwesome,
      color: "bg-yellow-500"
    },
    {
      title: t('ai.features.list.insights.title'),
      description: t('ai.features.list.insights.description'),
      icon: Psychology,
      color: "bg-red-500"
    },
    {
      title: t('ai.features.list.integration.title'),
      description: t('ai.features.list.integration.description'),
      icon: Autorenew,
      color: "bg-indigo-500"
    }
  ];

  return (
    <>
      <Helmet>
        <title>{t('ai.meta.title')}</title>
        <meta name="description" content={t('ai.meta.description')} />
      </Helmet>

      <div className="min-h-screen">
        <Header />

        {/* Hero Section */}
        <section className="relative overflow-hidden bg-gradient-to-br from-violet-900 to-indigo-900 pt-32 pb-20 sm:pt-40 sm:pb-32">
          <div className="absolute inset-0">
            <div 
              className="absolute inset-0 bg-[url(/grid.svg)] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]" 
              style={{ 
                backgroundSize: '30px 30px',
                opacity: 0.2,
                maskImage: 'linear-gradient(to bottom, white, transparent)'
              }} 
            />
          </div>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="inline-flex items-center gap-2 px-4 py-2 bg-violet-800/50 rounded-full mb-8"
              >
                <SmartToy className="w-5 h-5 text-violet-300" />
                <span className="text-sm font-medium text-violet-300">{t('ai.hero.badge')}</span>
              </motion.div>
              
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className="text-4xl font-bold text-white sm:text-6xl mb-6"
                dangerouslySetInnerHTML={{ __html: t('ai.hero.title').replace('\n', '<br/>') }}
              />
              
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="max-w-2xl mx-auto text-lg text-violet-200 mb-8"
              >
                {t('ai.hero.description')}
              </motion.p>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                <a
                  href="https://wo1rar62if6.typeform.com/to/Dwc8VtGy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center gap-2 px-6 py-3 bg-violet-600 text-white rounded-lg font-semibold hover:bg-violet-700 transition-colors"
                >
                  <Notifications className="w-5 h-5" />
                  {t('ai.hero.cta')}
                </a>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Features Grid */}
        <section className="py-20 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-slate-900 mb-4">
                {t('ai.features.title')}
              </h2>
              <p className="text-lg text-slate-600 max-w-2xl mx-auto">
                {t('ai.features.description')}
              </p>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow p-6"
                >
                  <div className={`${feature.color} w-12 h-12 rounded-lg flex items-center justify-center mb-4`}>
                    <feature.icon className="w-6 h-6 text-white" />
                  </div>
                  <h3 className="text-xl font-semibold text-slate-900 mb-2">{feature.title}</h3>
                  <p className="text-slate-600">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* How It Works */}
        <section className="py-20 bg-slate-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-slate-900 mb-4">
                {t('ai.workflow.title')}
              </h2>
              <p className="text-lg text-slate-600 max-w-2xl mx-auto">
                {t('ai.workflow.description')}
              </p>
            </div>

            <div className="max-w-3xl mx-auto space-y-12">
              <div className="relative pl-10 border-l-2 border-violet-200">
                <div className="absolute left-0 -translate-x-1/2 w-4 h-4 rounded-full bg-violet-600" />
                <h3 className="text-lg font-semibold text-slate-900 mb-2">
                  {t('ai.workflow.steps.morning.title')}
                </h3>
                <p className="text-slate-600">
                  {t('ai.workflow.steps.morning.description')}
                </p>
              </div>

              <div className="relative pl-10 border-l-2 border-violet-200">
                <div className="absolute left-0 -translate-x-1/2 w-4 h-4 rounded-full bg-violet-600" />
                <h3 className="text-lg font-semibold text-slate-900 mb-2">
                  {t('ai.workflow.steps.client.title')}
                </h3>
                <p className="text-slate-600">
                  {t('ai.workflow.steps.client.description')}
                </p>
              </div>

              <div className="relative pl-10 border-l-2 border-violet-200">
                <div className="absolute left-0 -translate-x-1/2 w-4 h-4 rounded-full bg-violet-600" />
                <h3 className="text-lg font-semibold text-slate-900 mb-2">
                  {t('ai.workflow.steps.documents.title')}
                </h3>
                <p className="text-slate-600">
                  {t('ai.workflow.steps.documents.description')}
                </p>
              </div>

              <div className="relative pl-10">
                <div className="absolute left-0 -translate-x-1/2 w-4 h-4 rounded-full bg-violet-600" />
                <h3 className="text-lg font-semibold text-slate-900 mb-2">
                  {t('ai.workflow.steps.summary.title')}
                </h3>
                <p className="text-slate-600">
                  {t('ai.workflow.steps.summary.description')}
                </p>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
} 