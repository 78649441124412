import React from 'react';
import type { Locale } from '../../lib/i18n/translations';

const flags: Record<Locale, string> = {
  en: "🇺🇸",
  es: "🇪🇸",
  fr: "🇫🇷"
};

interface FlagIconProps {
  locale: Locale;
  className?: string;
}

export default function FlagIcon({ locale, className = "" }: FlagIconProps) {
  return (
    <span className={`text-xl ${className}`} role="img" aria-label={`${locale} flag`}>
      {flags[locale]}
    </span>
  );
} 