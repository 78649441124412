import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Calculate } from '@mui/icons-material';

interface PayeResult {
  grossPay: number;
  taxableIncome: number;
  paye: number;
  nhif: number;
  nssf: number;
  housingLevy: number;
  netPay: number;
  employerNssf: number;
  employerHousingLevy: number;
  nita: number;
}

export default function PayeCalculator() {
  const [grossSalary, setGrossSalary] = useState<string>('');
  const [deductions, setDeductions] = useState({
    nssf: true,
    paye: true,
    housingLevy: true,
    nhif: true
  });
  const [result, setResult] = useState<PayeResult | null>(null);

  const calculatePaye = (grossPay: number): PayeResult => {
    // NSSF Calculation (New Rates)
    const nssf = deductions.nssf ? Math.min(grossPay * 0.06, 1080) : 0;
    const employerNssf = Math.min(grossPay * 0.06, 1080);

    // Housing Levy
    const housingLevy = deductions.housingLevy ? grossPay * 0.015 : 0;
    const employerHousingLevy = grossPay * 0.015;

    // NITA
    const nita = 50;

    // Taxable Income
    const taxableIncome = grossPay - nssf;

    // PAYE Calculation
    let paye = 0;
    if (deductions.paye && taxableIncome > 0) {
      if (taxableIncome <= 24000) {
        paye = taxableIncome * 0.1;
      } else if (taxableIncome <= 32333) {
        paye = 2400 + (taxableIncome - 24000) * 0.25;
      } else if (taxableIncome <= 500000) {
        paye = 4483.25 + (taxableIncome - 32333) * 0.30;
      } else if (taxableIncome <= 800000) {
        paye = 144783.25 + (taxableIncome - 500000) * 0.325;
      } else {
        paye = 242283.25 + (taxableIncome - 800000) * 0.35;
      }
    }

    // NHIF Calculation
    let nhif = 0;
    if (deductions.nhif) {
      if (grossPay <= 5999) nhif = 150;
      else if (grossPay <= 7999) nhif = 300;
      else if (grossPay <= 11999) nhif = 400;
      else if (grossPay <= 14999) nhif = 500;
      else if (grossPay <= 19999) nhif = 600;
      else if (grossPay <= 24999) nhif = 750;
      else if (grossPay <= 29999) nhif = 850;
      else if (grossPay <= 34999) nhif = 900;
      else if (grossPay <= 39999) nhif = 950;
      else if (grossPay <= 44999) nhif = 1000;
      else if (grossPay <= 49999) nhif = 1100;
      else if (grossPay <= 59999) nhif = 1200;
      else if (grossPay <= 69999) nhif = 1300;
      else if (grossPay <= 79999) nhif = 1400;
      else if (grossPay <= 89999) nhif = 1500;
      else if (grossPay <= 99999) nhif = 1600;
      else nhif = 1700;
    }

    // Net Pay Calculation
    const netPay = grossPay - paye - nhif - nssf - housingLevy;

    return {
      grossPay,
      taxableIncome,
      paye,
      nhif,
      nssf,
      housingLevy,
      netPay,
      employerNssf,
      employerHousingLevy,
      nita
    };
  };

  const handleCalculate = (e: React.FormEvent) => {
    e.preventDefault();
    const grossPay = parseFloat(grossSalary);
    if (!isNaN(grossPay)) {
      setResult(calculatePaye(grossPay));
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 to-white p-8">
      <div className="max-w-6xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <Link to="/calculators" className="inline-block mb-8 text-slate-600 hover:text-slate-800">
            ← Back to Calculators
          </Link>
          <div className="flex items-center justify-center gap-3 mb-4">
            <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-red-600">
              <Calculate className="h-6 w-6 text-white" />
            </div>
          </div>
          <h1 className="text-4xl font-bold text-slate-800 mb-4">
            Kenya PAYE Calculator
          </h1>
          <p className="text-lg text-slate-600">
            Calculate your net salary and statutory deductions
          </p>
        </motion.div>

        <div className="grid gap-8 md:grid-cols-2">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className="bg-white rounded-xl shadow-xl p-8"
          >
            <form onSubmit={handleCalculate} className="space-y-8">
              <div>
                <label className="block text-lg font-medium text-slate-700 mb-3">
                  Monthly Gross Salary (KES)
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                    <span className="text-slate-500 text-lg">KES</span>
                  </div>
                  <input
                    type="text"
                    value={grossSalary}
                    onChange={(e) => {
                      // Only allow numbers
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      setGrossSalary(value);
                    }}
                    className="w-full h-14 pl-16 pr-4 text-lg border border-blue-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="10000"
                    required
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                    <div className="h-4 w-4 text-slate-400">
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="6 9 12 15 18 9"></polyline>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-6">
                <h3 className="font-semibold text-slate-800">Deductions</h3>
                <div className="grid gap-4">
                  {[
                    { key: 'paye', label: 'PAYE Tax' },
                    { key: 'nssf', label: 'NSSF Contribution' },
                    { key: 'nhif', label: 'NHIF/SHIF' },
                    { key: 'housingLevy', label: 'Housing Development Levy' }
                  ].map((item) => (
                    <div key={item.key} className="flex items-center justify-between bg-slate-50 p-4 rounded-lg">
                      <label className="text-sm font-medium text-slate-700">{item.label}</label>
                      <button
                        type="button"
                        onClick={() => setDeductions(prev => ({ ...prev, [item.key]: !prev[item.key as keyof typeof deductions] }))}
                        className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
                          deductions[item.key as keyof typeof deductions] ? 'bg-red-600' : 'bg-slate-200'
                        }`}
                      >
                        <span className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                          deductions[item.key as keyof typeof deductions] ? 'translate-x-6' : 'translate-x-1'
                        }`} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <button
                type="submit"
                className="w-full bg-red-600 text-white rounded-lg px-4 py-3 hover:bg-red-700 transition-colors font-medium"
              >
                Calculate Salary
              </button>
            </form>
          </motion.div>

          {result && (
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              className="bg-white rounded-xl shadow-xl p-8"
            >
              <div className="space-y-8">
                <div className="text-center p-6 bg-red-50 rounded-lg">
                  <p className="text-sm font-medium text-red-600 mb-1">Net Pay</p>
                  <p className="text-4xl font-bold text-red-700">
                    KES {result.netPay.toLocaleString()}
                  </p>
                </div>

                <div className="space-y-6">
                  <div>
                    <h3 className="font-semibold text-slate-800 mb-4">Breakdown</h3>
                    <div className="space-y-3">
                      <div className="flex justify-between items-center p-3 bg-slate-50 rounded-lg">
                        <span className="text-slate-600">Gross Salary</span>
                        <span className="font-medium text-slate-800">KES {result.grossPay.toLocaleString()}</span>
                      </div>
                      {result.paye > 0 && (
                        <div className="flex justify-between items-center p-3 bg-slate-50 rounded-lg">
                          <span className="text-slate-600">PAYE Tax</span>
                          <span className="font-medium text-red-600">- KES {result.paye.toLocaleString()}</span>
                        </div>
                      )}
                      {result.nssf > 0 && (
                        <div className="flex justify-between items-center p-3 bg-slate-50 rounded-lg">
                          <span className="text-slate-600">NSSF</span>
                          <span className="font-medium text-red-600">- KES {result.nssf.toLocaleString()}</span>
                        </div>
                      )}
                      {result.nhif > 0 && (
                        <div className="flex justify-between items-center p-3 bg-slate-50 rounded-lg">
                          <span className="text-slate-600">NHIF</span>
                          <span className="font-medium text-red-600">- KES {result.nhif.toLocaleString()}</span>
                        </div>
                      )}
                      {result.housingLevy > 0 && (
                        <div className="flex justify-between items-center p-3 bg-slate-50 rounded-lg">
                          <span className="text-slate-600">Housing Levy</span>
                          <span className="font-medium text-red-600">- KES {result.housingLevy.toLocaleString()}</span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <h3 className="font-semibold text-slate-800 mb-4">Employer Contributions</h3>
                    <div className="space-y-3">
                      <div className="flex justify-between items-center p-3 bg-slate-50 rounded-lg">
                        <span className="text-slate-600">NSSF (Employer)</span>
                        <span className="font-medium text-slate-800">KES {result.employerNssf.toLocaleString()}</span>
                      </div>
                      <div className="flex justify-between items-center p-3 bg-slate-50 rounded-lg">
                        <span className="text-slate-600">Housing Levy (Employer)</span>
                        <span className="font-medium text-slate-800">KES {result.employerHousingLevy.toLocaleString()}</span>
                      </div>
                      <div className="flex justify-between items-center p-3 bg-slate-50 rounded-lg">
                        <span className="text-slate-600">NITA</span>
                        <span className="font-medium text-slate-800">KES {result.nita.toLocaleString()}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
} 