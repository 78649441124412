import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Calculate, Description, CheckCircle } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';

export default function PayeCalculatorLanding() {
  return (
    <>
      <Helmet prioritizeSeoTags>
        {/* Basic metadata */}
        <html lang="en" />
        <title>Kenya PAYE Tax Calculator 2024 | KRA Tax Rates & Salary Calculator</title>
        <meta name="robots" content="index, follow" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta 
          name="description" 
          content="Free Kenya PAYE Calculator 2024. Calculate your net salary, PAYE tax, NHIF, NSSF, and Housing Levy instantly. Updated with latest KRA tax rates and bands. Most accurate salary calculator in Kenya." 
        />
        <meta 
          name="keywords" 
          content="PAYE calculator Kenya, KRA tax calculator, salary calculator Kenya, NHIF calculator, NSSF calculator, Housing Levy calculator, net salary calculator Kenya, 2024 tax calculator" 
        />
        <link rel="canonical" href="https://lipana.app/calculators/paye" />
        
        {/* Open Graph tags */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Lipana" />
        <meta property="og:url" content="https://lipana.app/calculators/paye" />
        <meta property="og:title" content="Kenya PAYE Tax Calculator 2024 | Latest KRA Tax Rates" />
        <meta 
          property="og:description" 
          content="Calculate your Kenya PAYE tax, NHIF, NSSF, and Housing Levy instantly. Most accurate and up-to-date calculator with 2024 KRA rates." 
        />
        <meta property="og:image" content="https://lipana.app/images/paye-calculator-og.png" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@lipanaApp" />
        <meta name="twitter:creator" content="@lipanaApp" />
        <meta name="twitter:title" content="Kenya PAYE Tax Calculator 2024" />
        <meta 
          name="twitter:description" 
          content="Free Kenya PAYE Calculator with latest KRA rates. Most accurate salary calculator in Kenya." 
        />
        <meta name="twitter:image" content="https://lipana.app/images/paye-calculator-twitter.png" />
        
        {/* Schema.org JSON-LD */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebApplication",
              "name": "Kenya PAYE Tax Calculator",
              "description": "Free online calculator for computing PAYE tax, NHIF, NSSF, and Housing Levy in Kenya using latest 2024 rates",
              "applicationCategory": "FinanceApplication",
              "operatingSystem": "Any",
              "url": "https://lipana.app/calculators/paye",
              "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "KES"
              },
              "featureList": [
                "Latest 2024 KRA tax bands",
                "NHIF calculations",
                "NSSF calculations",
                "Housing Levy computations",
                "Net salary calculator",
                "Employer contributions"
              ],
              "author": {
                "@type": "Organization",
                "name": "Lipana",
                "url": "https://lipana.app"
              },
              "dateModified": "${new Date().toISOString().split('T')[0]}"
            }
          `}
        </script>
      </Helmet>

      <div className="min-h-screen bg-gradient-to-br from-slate-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center"
          >
            <div className="flex items-center justify-center gap-3 mb-8">
              <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-red-600">
                <Calculate className="h-6 w-6 text-white" />
              </div>
            </div>
            <h1 className="text-4xl font-bold text-slate-800 mb-4">
              Kenya PAYE Tax Calculator 2024
            </h1>
            <p className="text-lg text-slate-600 max-w-2xl mx-auto">
              The most accurate PAYE calculator in Kenya. Calculate your net salary, PAYE tax, NHIF, NSSF, and Housing Levy using the latest KRA rates.
            </p>
          </motion.div>

          <div className="mt-16 grid gap-8 md:grid-cols-2">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.1 }}
              className="space-y-6"
            >
              <h2 className="text-2xl font-bold text-slate-800">Key Features</h2>
              <ul className="space-y-4">
                {[
                  'Latest 2024 KRA tax bands and rates',
                  'Updated NHIF contributions with new rates',
                  'New NSSF rates (6% employer and employee)',
                  'Housing Development Levy calculations (1.5%)',
                  'Instant net salary calculation',
                  'Detailed breakdown of all deductions',
                  'Employer contribution calculations',
                  'Mobile-friendly and easy to use'
                ].map((feature, index) => (
                  <li key={index} className="flex items-start gap-3">
                    <CheckCircle className="w-6 h-6 text-green-500 flex-shrink-0" />
                    <span className="text-slate-600">{feature}</span>
                  </li>
                ))}
              </ul>

              <div className="pt-6">
                <Link
                  to="/calculators/paye/generate"
                  className="inline-flex items-center gap-2 px-6 py-3 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
                >
                  <Calculate className="w-5 h-5" />
                  Calculate PAYE Now
                </Link>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
              className="space-y-6"
            >
              <h2 className="text-2xl font-bold text-slate-800">2024 Tax Information</h2>
              <div className="bg-white rounded-xl shadow-sm border border-slate-200 divide-y divide-slate-200">
                <div className="p-4">
                  <h3 className="font-semibold text-slate-800">PAYE Tax Bands</h3>
                  <ul className="mt-2 space-y-1 text-sm text-slate-600">
                    <li>• Up to KES 24,000: 10%</li>
                    <li>• KES 24,001 - 32,333: 25%</li>
                    <li>• KES 32,334 - 500,000: 30%</li>
                    <li>• KES 500,001 - 800,000: 32.5%</li>
                    <li>• Above KES 800,000: 35%</li>
                  </ul>
                </div>
                <div className="p-4">
                  <h3 className="font-semibold text-slate-800">NHIF Rates</h3>
                  <p className="text-sm text-slate-600 mt-1">
                    Updated NHIF rates from KES 150 to KES 1,700 based on salary brackets
                  </p>
                </div>
                <div className="p-4">
                  <h3 className="font-semibold text-slate-800">NSSF Calculations</h3>
                  <p className="text-sm text-slate-600 mt-1">
                    6% contribution each from employer and employee (Tier I & II)
                  </p>
                </div>
                <div className="p-4">
                  <h3 className="font-semibold text-slate-800">Housing Levy</h3>
                  <p className="text-sm text-slate-600 mt-1">
                    1.5% of gross pay from both employer and employee
                  </p>
                </div>
              </div>
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="mt-16 bg-slate-50 rounded-xl p-8"
          >
            <div className="max-w-3xl mx-auto">
              <h2 className="text-2xl font-bold text-slate-800 mb-6 text-center">
                How to Calculate Your PAYE
              </h2>
              <div className="grid gap-6 md:grid-cols-3">
                <div className="text-center">
                  <div className="w-12 h-12 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-4">
                    <span className="text-xl font-bold text-red-600">1</span>
                  </div>
                  <h3 className="font-semibold text-slate-800 mb-2">Enter Salary</h3>
                  <p className="text-sm text-slate-600">
                    Input your monthly gross salary in KES
                  </p>
                </div>
                <div className="text-center">
                  <div className="w-12 h-12 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-4">
                    <span className="text-xl font-bold text-red-600">2</span>
                  </div>
                  <h3 className="font-semibold text-slate-800 mb-2">Choose Deductions</h3>
                  <p className="text-sm text-slate-600">
                    Select applicable statutory deductions
                  </p>
                </div>
                <div className="text-center">
                  <div className="w-12 h-12 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-4">
                    <span className="text-xl font-bold text-red-600">3</span>
                  </div>
                  <h3 className="font-semibold text-slate-800 mb-2">View Results</h3>
                  <p className="text-sm text-slate-600">
                    Get instant breakdown of all calculations
                  </p>
                </div>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="mt-16 text-center"
          >
            <h2 className="text-2xl font-bold text-slate-800 mb-4">
              Frequently Asked Questions About PAYE Tax
            </h2>
            <div className="mt-8 grid gap-6 md:grid-cols-2 lg:grid-cols-3 text-left">
              {[
                {
                  q: "What is PAYE tax in Kenya?",
                  a: "PAYE (Pay As You Earn) is a method of collecting income tax from employees in Kenya. It is deducted by the employer from the employee's monthly salary and remitted to KRA."
                },
                {
                  q: "How is PAYE calculated in Kenya 2024?",
                  a: "PAYE is calculated using progressive tax bands: 10% up to KES 24,000, 25% for KES 24,001-32,333, 30% for KES 32,334-500,000, 32.5% for KES 500,001-800,000, and 35% above KES 800,000."
                },
                {
                  q: "What are the current NHIF rates in Kenya?",
                  a: "NHIF rates range from KES 150 to KES 1,700 based on salary brackets. For example, those earning KES 5,999 or less pay KES 150, while those earning KES 100,000 and above pay KES 1,700."
                },
                {
                  q: "How much is Housing Levy in Kenya?",
                  a: "The Housing Development Levy is 1.5% of the gross salary, matched by an equal contribution from the employer, making it a total of 3% per employee."
                },
                {
                  q: "What is the new NSSF contribution rate?",
                  a: "The NSSF contribution rate is 6% of pensionable pay from both employee and employer, with a maximum contribution of KES 1,080 each."
                },
                {
                  q: "How accurate is this PAYE calculator?",
                  a: "Our calculator is updated with the latest 2024 KRA tax rates and regulations, including recent changes to PAYE bands, NHIF, NSSF, and Housing Levy calculations."
                }
              ].map((faq, index) => (
                <div key={index} className="bg-white p-6 rounded-xl shadow-sm border border-slate-200">
                  <h3 className="font-semibold text-slate-800 mb-2">{faq.q}</h3>
                  <p className="text-sm text-slate-600">{faq.a}</p>
                </div>
              ))}
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="mt-16 bg-white rounded-xl shadow-sm border border-slate-200 p-8"
          >
            <h2 className="text-2xl font-bold text-slate-800 mb-6 text-center">
              Why Choose Our PAYE Calculator?
            </h2>
            <div className="grid gap-6 md:grid-cols-3">
              <div className="text-center">
                <h3 className="font-semibold text-slate-800 mb-2">Always Up to Date</h3>
                <p className="text-sm text-slate-600">
                  Continuously updated with the latest KRA tax rates and regulations to ensure accuracy
                </p>
              </div>
              <div className="text-center">
                <h3 className="font-semibold text-slate-800 mb-2">Comprehensive</h3>
                <p className="text-sm text-slate-600">
                  Includes all statutory deductions including PAYE, NHIF, NSSF, and Housing Levy
                </p>
              </div>
              <div className="text-center">
                <h3 className="font-semibold text-slate-800 mb-2">Easy to Use</h3>
                <p className="text-sm text-slate-600">
                  Simple interface with instant calculations and detailed breakdowns
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
} 