export type Locale = 'en' | 'es' | 'fr';

export const translations = {
  en: {
    home: {
      title: 'Professional Document Generation Suite',
      description: 'Create professional documents in seconds with our AI-powered tools',
      nav: {
        home: 'Home',
        about: 'About',
        contact: 'Contact',
        tools: 'Tools',
        ai: 'AI'
      },
      tools: {
        contract: 'Contract Generator',
        contractDesc: 'Create professional contracts powered by AI',
        invoice: 'Invoice Generator',
        invoiceDesc: 'Generate professional invoices in seconds',
        proposal: 'Proposal Generator',
        proposalDesc: 'Craft winning business proposals with ease',
        receipt: 'Receipt Generator',
        receiptDesc: 'Generate professional receipts instantly',
        quote: 'Quote Generator',
        quoteDesc: 'Create quick and professional price quotes',
        sow: 'Statement of Work',
        sowDesc: 'Generate detailed project specifications',
        brief: 'Project Brief',
        briefDesc: 'Create structured project briefs and requirements',
        nda: 'NDA Generator',
        ndaDesc: 'Create secure non-disclosure agreements',
        timesheet: 'Time Sheet',
        timesheetDesc: 'Track and manage your working hours',
        expense: 'Expense Report',
        expenseDesc: 'Track and report business expenses',
        status: 'Status Report',
        statusDesc: 'Generate professional status reports'
      },
      cta: {
        getStarted: 'Get Started',
        learnMore: 'Learn More',
        tryNow: 'Try Now'
      }
    },
    about: {
      meta: {
        title: 'About Lipana | Free Business Document Generator',
        description: 'Learn more about Lipana - your all-in-one solution for creating professional business documents. Free tools for freelancers and small businesses.'
      },
      title: 'About Lipana',
      description: 'Empowering freelancers and small businesses with professional document generation tools',
      mission: 'Our Mission',
      missionText: 'At Lipana, we believe that every freelancer and small business deserves access to professional-grade business documents. Our mission is to simplify the document creation process, making it accessible, efficient, and free for everyone.',
      features: {
        templates: {
          title: 'Professional Templates',
          description: 'Access a wide range of professionally designed templates for all your business documents.'
        },
        automation: {
          title: 'Smart Automation',
          description: 'Save time with automatic calculations and smart document generation features.'
        },
        security: {
          title: 'Secure & Private',
          description: 'Your data stays on your device. We don\'t store any of your information.'
        },
        free: {
          title: 'Free to Use',
          description: 'All our tools are completely free to use, with no hidden costs or subscriptions.'
        },
        speed: {
          title: 'Lightning Fast',
          description: 'Generate professional documents in seconds with our optimized platform.'
        },
        updates: {
          title: 'Regular Updates',
          description: 'We continuously improve our tools and add new features based on user feedback.'
        }
      },
      cta: {
        title: 'Ready to Get Started?',
        description: 'Join thousands of freelancers and small businesses who trust Lipana for their document generation needs.',
        button: 'Explore Our Tools'
      }
    },
    ai: {
      meta: {
        title: 'AI-Powered Document Generation | Lipana',
        description: 'Experience the power of AI in document generation. Create smarter, more efficient documents with our AI-powered tools.'
      },
      hero: {
        badge: 'Coming Soon',
        title: 'AI-Powered Document Generation',
        description: 'Harness the power of artificial intelligence to create professional documents in seconds',
        cta: 'Join the Waitlist'
      },
      features: {
        title: 'Your Intelligent Freelance Partner',
        description: 'Lipana AI learns your preferences, adapts to your workflow, and helps you manage your freelance business more efficiently.',
        list: {
          taskManagement: {
            title: 'Smart Task Management',
            description: 'Automatically prioritizes your tasks, sets reminders, and helps you stay on top of deadlines'
          },
          communication: {
            title: 'Client Communication',
            description: 'Drafts professional emails, follows up with clients, and manages your inbox intelligently'
          },
          automation: {
            title: 'Project Automation',
            description: 'Seamlessly integrates with your tools and automates repetitive tasks in your workflow'
          },
          documents: {
            title: 'Smart Document Generation',
            description: 'Creates contracts, proposals, and invoices using context from your conversations'
          },
          insights: {
            title: 'Proactive Insights',
            description: 'Provides business insights and suggestions to help you make better decisions'
          },
          integration: {
            title: 'Workflow Integration',
            description: 'Works with your favorite tools like Slack, Gmail, Trello, and more'
          }
        }
      },
      workflow: {
        title: 'Your Day with Lipana AI',
        description: 'Experience a more organized and efficient workday with your AI assistant',
        steps: {
          morning: {
            title: 'Morning Briefing',
            description: 'Lipana AI reviews your schedule, prioritizes tasks, and sends you a morning briefing with everything you need to know for the day.'
          },
          client: {
            title: 'Client Management',
            description: 'Handles client communications, drafts responses, and ensures no important messages slip through the cracks.'
          },
          documents: {
            title: 'Document Automation',
            description: 'Automatically generates necessary documents based on your conversations and project requirements.'
          },
          summary: {
            title: 'End-of-Day Summary',
            description: 'Provides a comprehensive overview of your day and prepares you for tomorrow.'
          }
        }
      }
    }
  },
  es: {
    home: {
      title: 'Suite de Generación de Documentos Profesionales',
      description: 'Crea documentos profesionales en segundos con nuestras herramientas potenciadas por IA',
      nav: {
        home: 'Inicio',
        about: 'Sobre Nosotros',
        contact: 'Contacto',
        tools: 'Herramientas',
        ai: 'IA'
      },
      tools: {
        contract: 'Generador de Contratos',
        contractDesc: 'Crea contratos profesionales potenciados por IA',
        invoice: 'Generador de Facturas',
        invoiceDesc: 'Genera facturas profesionales en segundos',
        proposal: 'Generador de Propuestas',
        proposalDesc: 'Crea propuestas de negocio ganadoras con facilidad',
        receipt: 'Generador de Recibos',
        receiptDesc: 'Genera recibos profesionales instantáneamente',
        quote: 'Generador de Cotizaciones',
        quoteDesc: 'Crea rápidas y profesionales cotizaciones de precio',
        sow: 'Declaración de Trabajo',
        sowDesc: 'Genera especificaciones detalladas del proyecto',
        brief: 'Brief del Proyecto',
        briefDesc: 'Crea breves estructurados del proyecto y requisitos',
        nda: 'Generador de NDA',
        ndaDesc: 'Crea acuerdos de confidencialidad seguros',
        timesheet: 'Hoja de Tiempo',
        timesheetDesc: 'Rastrea y administra tus horas de trabajo',
        expense: 'Informe de Gastos',
        expenseDesc: 'Rastrea y reporta gastos empresariales',
        status: 'Informe de Estado',
        statusDesc: 'Genera informes de estado profesionales'
      },
      cta: {
        getStarted: 'Comenzar',
        learnMore: 'Más Información',
        tryNow: 'Probar Ahora'
      }
    },
    about: {
      meta: {
        title: 'Sobre Lipana | Generador Gratuito de Documentos Comerciales',
        description: 'Conozca más sobre Lipana - su solución todo en uno para crear documentos comerciales profesionales. Herramientas gratuitas para freelancers y pequeñas empresas.'
      },
      title: 'Sobre Lipana',
      description: 'Empoderando freelancers y pequeñas empresas con herramientas de generación de documentos profesionales',
      mission: 'Nuestra Misión',
      missionText: 'En Lipana, creemos que cada freelancer y pequeña empresa merece acceso a documentos comerciales de nivel profesional. Nuestra misión es simplificar el proceso de creación de documentos, haciéndolo accesible, eficiente y gratuito para todos.',
      features: {
        templates: {
          title: 'Plantillas Profesionales',
          description: 'Acceda a una amplia gama de plantillas diseñadas profesionalmente para todos sus documentos comerciales.'
        },
        automation: {
          title: 'Automatización Inteligente',
          description: 'Ahorre tiempo con cálculos automáticos y funciones inteligentes de generación de documentos.'
        },
        security: {
          title: 'Seguro y Privado',
          description: 'Sus datos permanecen en su dispositivo. No almacenamos ninguna de su información.'
        },
        free: {
          title: 'Gratuito',
          description: 'Todas nuestras herramientas son completamente gratuitas, sin costos ocultos ni suscripciones.'
        },
        speed: {
          title: 'Ultra Rápido',
          description: 'Genere documentos profesionales en segundos con nuestra plataforma optimizada.'
        },
        updates: {
          title: 'Actualizaciones Regulares',
          description: 'Mejoramos continuamente nuestras herramientas y agregamos nuevas funciones basadas en los comentarios de los usuarios.'
        }
      },
      cta: {
        title: '¿Listo para Comenzar?',
        description: 'Únase a miles de freelancers y pequeñas empresas que confían en Lipana para sus necesidades de generación de documentos.',
        button: 'Explorar Nuestras Herramientas'
      }
    },
    ai: {
      meta: {
        title: 'Generación de Documentos con IA | Lipana',
        description: 'Experimente el poder de la IA en la generación de documentos. Cree documentos más inteligentes y eficientes con nuestras herramientas potenciadas por IA.'
      },
      hero: {
        badge: 'Próximamente',
        title: 'Generación de Documentos con IA',
        description: 'Aprovecha el poder de la inteligencia artificial para crear documentos profesionales en segundos',
        cta: 'Unirse a la Lista de Espera'
      },
      features: {
        title: 'Tu Socio Freelance Inteligente',
        description: 'Lipana AI aprende tus preferencias, se adapta a tu flujo de trabajo y te ayuda a gestionar tu negocio freelance de manera más eficiente.',
        list: {
          taskManagement: {
            title: 'Gestión Inteligente de Tareas',
            description: 'Prioriza automáticamente tus tareas, establece recordatorios y te ayuda a mantener el control de los plazos'
          },
          communication: {
            title: 'Comunicación con Clientes',
            description: 'Redacta correos electrónicos profesionales, hace seguimiento a los clientes y gestiona tu bandeja de entrada de manera inteligente'
          },
          automation: {
            title: 'Automatización de Proyectos',
            description: 'Se integra perfectamente con tus herramientas y automatiza tareas repetitivas en tu flujo de trabajo'
          },
          documents: {
            title: 'Generación Inteligente de Documentos',
            description: 'Crea contratos, propuestas y facturas utilizando el contexto de tus conversaciones'
          },
          insights: {
            title: 'Información Proactiva',
            description: 'Proporciona información comercial y sugerencias para ayudarte a tomar mejores decisiones'
          },
          integration: {
            title: 'Integración de Flujo de Trabajo',
            description: 'Funciona con tus herramientas favoritas como Slack, Gmail, Trello y más'
          }
        }
      },
      workflow: {
        title: 'Tu Día con Lipana AI',
        description: 'Experimenta un día laboral más organizado y eficiente con tu asistente de IA',
        steps: {
          morning: {
            title: 'Informe Matutino',
            description: 'Lipana AI revisa tu agenda, prioriza tareas y te envía un informe matutino con todo lo que necesitas saber para el día.'
          },
          client: {
            title: 'Gestión de Clientes',
            description: 'Maneja las comunicaciones con clientes, redacta respuestas y asegura que ningún mensaje importante se pierda.'
          },
          documents: {
            title: 'Automatización de Documentos',
            description: 'Genera automáticamente los documentos necesarios basándose en tus conversaciones y requisitos del proyecto.'
          },
          summary: {
            title: 'Resumen del Día',
            description: 'Proporciona una visión general completa de tu día y te prepara para mañana.'
          }
        }
      }
    }
  },
  fr: {
    home: {
      title: 'Suite de Génération de Documents Professionnels',
      description: 'Cr��ez des documents professionnels en quelques secondes avec nos outils alimentés par l\'IA',
      nav: {
        home: 'Accueil',
        about: 'À propos',
        contact: 'Contact',
        tools: 'Outils',
        ai: 'IA'
      },
      tools: {
        contract: 'Générateur de Contrats',
        contractDesc: 'Créez des contrats professionnels alimentés par l\'IA',
        invoice: 'Générateur de Factures',
        invoiceDesc: 'Générez des factures professionnelles en quelques secondes',
        proposal: 'Générateur de Propositions',
        proposalDesc: 'Créez des propositions de projet gagnantes avec facilité',
        receipt: 'Générateur de Reçus',
        receiptDesc: 'Générez des reçus professionnels instantanément',
        quote: 'Générateur de Devis',
        quoteDesc: 'Créez des devis professionnels rapides et professionnels',
        sow: 'Énoncé des Travaux',
        sowDesc: 'Générez des spécifications détaillées du projet',
        brief: 'Brief du Projet',
        briefDesc: 'Créez des briefs structurés du projet et des exigences',
        nda: 'Générateur de NDA',
        ndaDesc: 'Créez des accords de confidentialité sécurisés',
        timesheet: 'Feuille de Temps',
        timesheetDesc: 'Suivez et gérez vos heures de travail',
        expense: 'Rapport de Dépenses',
        expenseDesc: 'Suivez et rapportez les dépenses d\'entreprise',
        status: 'Rapport de Statut',
        statusDesc: 'Générez des rapports de statut professionnels'
      },
      cta: {
        getStarted: 'Commencer',
        learnMore: 'En Savoir Plus',
        tryNow: 'Essayer Maintenant'
      }
    },
    about: {
      meta: {
        title: 'À Propos de Lipana | Générateur Gratuit de Documents Professionnels',
        description: 'En savoir plus sur Lipana - votre solution tout-en-un pour créer des documents professionnels. Outils gratuits pour freelances et petites entreprises.'
      },
      title: 'À Propos de Lipana',
      description: 'Donner aux freelances et aux petites entreprises les moyens de générer des documents professionnels',
      mission: 'Notre Mission',
      missionText: 'Chez Lipana, nous croyons que chaque freelance et petite entreprise mérite d\'avoir accès à des documents professionnels. Notre mission est de simplifier le processus de création de documents, en le rendant accessible, efficace et gratuit pour tous.',
      features: {
        templates: {
          title: 'Modèles Professionnels',
          description: 'Accédez à une large gamme de modèles conçus professionnellement pour tous vos documents commerciaux.'
        },
        automation: {
          title: 'Automatisation Intelligente',
          description: 'Gagnez du temps avec des calculs automatiques et des fonctionnalités intelligentes de génération de documents.'
        },
        security: {
          title: 'Sécurisé et Privé',
          description: 'Vos données restent sur votre appareil. Nous ne stockons aucune de vos informations.'
        },
        free: {
          title: 'Gratuit',
          description: 'Tous nos outils sont entièrement gratuits, sans frais cachés ni abonnements.'
        },
        speed: {
          title: 'Ultra Rapide',
          description: 'Générez des documents professionnels en quelques secondes avec notre plateforme optimisée.'
        },
        updates: {
          title: 'Mises à Jour Régulières',
          description: 'Nous améliorons continuellement nos outils et ajoutons de nouvelles fonctionnalités basées sur les retours des utilisateurs.'
        }
      },
      cta: {
        title: 'Prêt à Commencer ?',
        description: 'Rejoignez des milliers de freelances et de petites entreprises qui font confiance à Lipana pour leurs besoins en génération de documents.',
        button: 'Explorer Nos Outils'
      }
    },
    ai: {
      meta: {
        title: 'Génération de Documents par IA | Lipana',
        description: 'Découvrez la puissance de l\'IA dans la génération de documents. Créez des documents plus intelligents et plus efficaces avec nos outils alimentés par l\'IA.'
      },
      hero: {
        badge: 'Bientôt Disponible',
        title: 'Génération de Documents par IA',
        description: 'Exploitez la puissance de l\'intelligence artificielle pour créer des documents professionnels en quelques secondes',
        cta: 'Rejoindre la Liste d\'Attente'
      },
      features: {
        title: 'Votre Partenaire Freelance Intelligent',
        description: 'Lipana AI apprend vos préférences, s\'adapte à votre flux de travail et vous aide à gérer votre activité freelance plus efficacement.',
        list: {
          taskManagement: {
            title: 'Gestion Intelligente des Tâches',
            description: 'Priorise automatiquement vos tâches, définit des rappels et vous aide à respecter les délais'
          },
          communication: {
            title: 'Communication Client',
            description: 'Rédige des emails professionnels, assure le suivi des clients et gère votre boîte de réception intelligemment'
          },
          automation: {
            title: 'Automatisation de Projet',
            description: 'S\'intègre parfaitement à vos outils et automatise les tâches répétitives dans votre flux de travail'
          },
          documents: {
            title: 'Génération Intelligente de Documents',
            description: 'Crée des contrats, des propositions et des factures en utilisant le contexte de vos conversations'
          },
          insights: {
            title: 'Informations Proactives',
            description: 'Fournit des informations commerciales et des suggestions pour vous aider à prendre de meilleures décisions'
          },
          integration: {
            title: 'Intégration de Flux de Travail',
            description: 'Fonctionne avec vos outils favoris comme Slack, Gmail, Trello et plus encore'
          }
        }
      },
      workflow: {
        title: 'Votre Journée avec Lipana AI',
        description: 'Expérimentez une journée de travail plus organisée et efficace avec votre assistant IA',
        steps: {
          morning: {
            title: 'Briefing Matinal',
            description: 'Lipana AI examine votre emploi du temps, priorise les tâches et vous envoie un briefing matinal avec tout ce que vous devez savoir pour la journée.'
          },
          client: {
            title: 'Gestion Client',
            description: 'Gère les communications clients, rédige les réponses et s\'assure qu\'aucun message important ne passe inaperçu.'
          },
          documents: {
            title: 'Automatisation des Documents',
            description: 'Génère automatiquement les documents nécessaires en fonction de vos conversations et des exigences du projet.'
          },
          summary: {
            title: 'Résumé de la Journée',
            description: 'Fournit un aperçu complet de votre journée et vous prépare pour demain.'
          }
        }
      }
    }
  }
};

export const defaultLocale: Locale = 'en';
export const locales: Locale[] = ['en', 'es', 'fr'];