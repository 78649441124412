import React from 'react';
import { TimesheetData, TimeEntry } from '../types/timesheet';
import { motion } from 'framer-motion';
import { Add, Delete, CloudUpload, Close } from '@mui/icons-material';

interface TimeSheetFormProps {
  formData: TimesheetData;
  setFormData: React.Dispatch<React.SetStateAction<TimesheetData>>;
  onSubmit: (e: React.FormEvent) => void;
}

export default function TimeSheetForm({ formData, setFormData, onSubmit }: TimeSheetFormProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 500000) {
        alert('File size should be less than 500KB');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          logo: reader.result as string
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const removeLogo = () => {
    setFormData(prev => ({
      ...prev,
      logo: undefined
    }));
  };

  const addTimeEntry = () => {
    setFormData(prev => ({
      ...prev,
      entries: [...prev.entries, {
        date: new Date().toISOString().split('T')[0],
        project: '',
        task: '',
        startTime: '09:00',
        endTime: '17:00',
        duration: 8,
        billable: true,
        notes: ''
      }]
    }));
  };

  const removeTimeEntry = (index: number) => {
    setFormData(prev => {
      const newEntries = prev.entries.filter((_, i) => i !== index);
      const totalHours = newEntries.reduce((sum, entry) => sum + entry.duration, 0);
      const billableHours = newEntries.reduce((sum, entry) => entry.billable ? sum + entry.duration : sum, 0);
      const nonBillableHours = totalHours - billableHours;
      const totalAmount = billableHours * prev.rate;

      return {
        ...prev,
        entries: newEntries,
        totalHours,
        billableHours,
        nonBillableHours,
        totalAmount
      };
    });
  };

  const updateTimeEntry = (index: number, field: keyof TimeEntry, value: string | number | boolean) => {
    setFormData(prev => {
      const newEntries = [...prev.entries];
      newEntries[index] = {
        ...newEntries[index],
        [field]: value
      };

      // If updating times, recalculate duration
      if (field === 'startTime' || field === 'endTime') {
        const start = new Date(`2000-01-01T${newEntries[index].startTime}`);
        const end = new Date(`2000-01-01T${newEntries[index].endTime}`);
        const duration = (end.getTime() - start.getTime()) / (1000 * 60 * 60); // Convert to hours
        newEntries[index].duration = duration;
      }

      const totalHours = newEntries.reduce((sum, entry) => sum + entry.duration, 0);
      const billableHours = newEntries.reduce((sum, entry) => entry.billable ? sum + entry.duration : sum, 0);
      const nonBillableHours = totalHours - billableHours;
      const totalAmount = billableHours * prev.rate;

      return {
        ...prev,
        entries: newEntries,
        totalHours,
        billableHours,
        nonBillableHours,
        totalAmount
      };
    });
  };

  const TimeEntryRow = ({ entry, index, updateTimeEntry, removeTimeEntry }: {
    entry: TimeEntry;
    index: number;
    updateTimeEntry: (index: number, field: keyof TimeEntry, value: any) => void;
    removeTimeEntry: (index: number) => void;
  }) => {
    // Get the min/max dates from the parent timesheet period
    const minDate = formData.periodStart;
    const maxDate = formData.periodEnd;

    return (
      <div className="grid grid-cols-12 gap-4 items-start bg-white p-4 rounded-lg border border-gray-200 hover:border-gray-300 transition-colors">
        <div className="col-span-2">
          <input
            type="date"
            value={entry.date}
            min={minDate}
            max={maxDate}
            onChange={(e) => updateTimeEntry(index, 'date', e.target.value)}
            className="w-full rounded-lg border border-gray-300 px-4 py-2.5 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
          />
        </div>
        
        <div className="col-span-2">
          <select
            value={entry.project}
            onChange={(e) => updateTimeEntry(index, 'project', e.target.value)}
            className="w-full rounded-lg border border-gray-300 px-4 py-2.5 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
          >
            <option value="">Select Project</option>
            <option value={formData.project}>{formData.project}</option>
          </select>
        </div>
        
        <div className="col-span-2">
          <input
            type="text"
            placeholder="Task Description"
            value={entry.task}
            onChange={(e) => updateTimeEntry(index, 'task', e.target.value)}
            className="w-full rounded-lg border border-gray-300 px-4 py-2.5 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
          />
        </div>
        
        <div className="col-span-1">
          <input
            type="time"
            value={entry.startTime}
            onChange={(e) => updateTimeEntry(index, 'startTime', e.target.value)}
            step="900" // 15-minute intervals
            className="w-full rounded-lg border border-gray-300 px-4 py-2.5 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
          />
        </div>
        
        <div className="col-span-1">
          <input
            type="time"
            value={entry.endTime}
            onChange={(e) => updateTimeEntry(index, 'endTime', e.target.value)}
            step="900" // 15-minute intervals
            className="w-full rounded-lg border border-gray-300 px-4 py-2.5 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
          />
        </div>
        
        <div className="col-span-1">
          <div className="px-4 py-2.5 bg-gray-50 rounded-lg border border-gray-200 text-center font-medium text-gray-700">
            {entry.duration.toFixed(2)}h
          </div>
        </div>
        
        <div className="col-span-2">
          <label className="flex items-center space-x-2 px-2 py-2.5">
            <input
              type="checkbox"
              checked={entry.billable}
              onChange={(e) => updateTimeEntry(index, 'billable', e.target.checked)}
              className="rounded border-gray-300 text-pink-600 focus:ring-pink-500"
            />
            <span className="text-sm text-gray-700">Billable</span>
          </label>
        </div>
        
        <div className="col-span-1">
          <button
            type="button"
            onClick={() => removeTimeEntry(index)}
            className="w-full h-full flex items-center justify-center text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-lg transition-colors"
          >
            <Delete className="w-5 h-5" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <form onSubmit={onSubmit} className="space-y-8">
      {/* Basic Information */}
      <div className="space-y-6">
        <h3 className="text-lg font-semibold text-slate-800">Basic Information</h3>
        
        {/* Logo Upload */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Company Logo (optional)
          </label>
          {formData.logo ? (
            <div className="relative w-40 h-20 mb-2">
              <img
                src={formData.logo}
                alt="Company logo"
                className="w-full h-full object-contain"
              />
              <button
                type="button"
                onClick={removeLogo}
                className="absolute -top-2 -right-2 p-1 bg-red-100 rounded-full text-red-500 hover:bg-red-200"
              >
                <Close className="w-4 h-4" />
              </button>
            </div>
          ) : (
            <div className="flex items-center justify-center w-full">
              <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-slate-500 rounded-lg border-2 border-dashed border-slate-200 cursor-pointer hover:bg-slate-50">
                <CloudUpload className="w-8 h-8 mb-2" />
                <span className="text-sm">Upload logo (max 500KB)</span>
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handleLogoUpload}
                />
              </label>
            </div>
          )}
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Timesheet Number
            </label>
            <input
              type="text"
              name="timesheetNumber"
              value={formData.timesheetNumber}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Date Created
            </label>
            <input
              type="date"
              name="dateCreated"
              value={formData.dateCreated}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Period Start
            </label>
            <input
              type="date"
              name="periodStart"
              value={formData.periodStart}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Period End
            </label>
            <input
              type="date"
              name="periodEnd"
              value={formData.periodEnd}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>
      </div>

      {/* Employee Details */}
      <div className="space-y-6">
        <h3 className="text-lg font-semibold text-slate-800">Employee Details</h3>
        
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Employee Name
            </label>
            <input
              type="text"
              name="employeeName"
              value={formData.employeeName}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Employee ID
            </label>
            <input
              type="text"
              name="employeeId"
              value={formData.employeeId}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email
            </label>
            <input
              type="email"
              name="employeeEmail"
              value={formData.employeeEmail}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Department
            </label>
            <input
              type="text"
              name="department"
              value={formData.department}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Supervisor
            </label>
            <input
              type="text"
              name="supervisor"
              value={formData.supervisor}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Rate (per hour)
            </label>
            <input
              type="number"
              name="rate"
              value={formData.rate}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Client (optional)
            </label>
            <input
              type="text"
              name="client"
              value={formData.client}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Project (optional)
            </label>
            <input
              type="text"
              name="project"
              value={formData.project}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>
      </div>

      {/* Time Entries */}
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold text-slate-800">Time Entries</h3>
          <button
            type="button"
            onClick={addTimeEntry}
            className="flex items-center gap-2 px-4 py-2 text-sm text-slate-800 hover:bg-slate-100 rounded-lg transition-colors"
          >
            <Add className="w-4 h-4" />
            Add Entry
          </button>
        </div>
        
        <div className="space-y-4">
          {formData.entries.map((entry, index) => (
            <TimeEntryRow
              key={index}
              entry={entry}
              index={index}
              updateTimeEntry={updateTimeEntry}
              removeTimeEntry={removeTimeEntry}
            />
          ))}
        </div>

        <div className="flex justify-end">
          <div className="w-64 space-y-3">
            <div className="flex justify-between">
              <span className="text-gray-600">Total Hours</span>
              <span className="font-medium">{formData.totalHours.toFixed(2)}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Billable Hours</span>
              <span className="font-medium">{formData.billableHours.toFixed(2)}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Non-Billable Hours</span>
              <span className="font-medium">{formData.nonBillableHours.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-lg font-semibold border-t border-gray-200 pt-2">
              <span>Total Amount</span>
              <span>${formData.totalAmount.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Comments */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Comments
        </label>
        <textarea
          name="comments"
          value={formData.comments}
          onChange={handleChange}
          rows={4}
          className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          placeholder="Any additional notes or comments..."
        />
      </div>

      <div className="pt-6">
        <button
          type="submit"
          className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 transition-all duration-200"
        >
          Preview Time Sheet
        </button>
      </div>
    </form>
  );
} 