import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Description, AutoGraph, Security, Support, Speed, Code } from '@mui/icons-material';
import { useTranslation } from '../lib/i18n/TranslationContext';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function About() {
  const { t } = useTranslation();

  const features = [
    {
      icon: Description,
      titleKey: 'about.features.templates.title',
      descriptionKey: 'about.features.templates.description',
      color: "bg-blue-500"
    },
    {
      icon: AutoGraph,
      titleKey: 'about.features.automation.title',
      descriptionKey: 'about.features.automation.description',
      color: "bg-green-500"
    },
    {
      icon: Security,
      titleKey: 'about.features.security.title',
      descriptionKey: 'about.features.security.description',
      color: "bg-purple-500"
    },
    {
      icon: Support,
      titleKey: 'about.features.free.title',
      descriptionKey: 'about.features.free.description',
      color: "bg-red-500"
    },
    {
      icon: Speed,
      titleKey: 'about.features.speed.title',
      descriptionKey: 'about.features.speed.description',
      color: "bg-yellow-500"
    },
    {
      icon: Code,
      titleKey: 'about.features.updates.title',
      descriptionKey: 'about.features.updates.description',
      color: "bg-indigo-500"
    }
  ];

  return (
    <>
      <Helmet>
        <title>{t('about.meta.title')}</title>
        <meta name="description" content={t('about.meta.description')} />
      </Helmet>

      <div className="min-h-screen">
        <Header />
        
        <main className="pt-32 pb-20">
          {/* Hero Section */}
          <div className="relative overflow-hidden bg-gradient-to-br from-slate-900 to-slate-800 py-20 mb-20">
            <div 
              className="absolute inset-0 bg-[url(/grid.svg)] bg-center"
              style={{ 
                backgroundSize: '30px 30px',
                opacity: 0.2,
                maskImage: 'linear-gradient(to bottom, white, transparent)'
              }} 
            />
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-4xl font-bold text-white mb-6"
              >
                {t('about.title')}
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className="text-xl text-slate-300 max-w-3xl mx-auto"
              >
                {t('about.description')}
              </motion.p>
            </div>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {/* Mission Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-center mb-20"
            >
              <h2 className="text-3xl font-bold text-slate-900 mb-6">{t('about.mission')}</h2>
              <p className="text-lg text-slate-600 max-w-3xl mx-auto">
                {t('about.missionText')}
              </p>
            </motion.div>

            {/* Features Grid */}
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-20">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow p-6"
                >
                  <div className={`${feature.color} w-12 h-12 rounded-lg flex items-center justify-center mb-4`}>
                    <feature.icon className="w-6 h-6 text-white" />
                  </div>
                  <h3 className="text-xl font-semibold text-slate-900 mb-2">
                    {t(feature.titleKey)}
                  </h3>
                  <p className="text-slate-600">
                    {t(feature.descriptionKey)}
                  </p>
                </motion.div>
              ))}
            </div>

            {/* CTA Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="text-center bg-gradient-to-r from-slate-800 to-slate-900 rounded-2xl p-12"
            >
              <h2 className="text-3xl font-bold text-white mb-6">{t('about.cta.title')}</h2>
              <p className="text-lg text-slate-300 mb-8 max-w-2xl mx-auto">
                {t('about.cta.description')}
              </p>
              <Link
                to="/"
                className="inline-flex items-center px-6 py-3 bg-white text-slate-900 rounded-lg font-semibold hover:bg-slate-50 transition-colors"
              >
                {t('about.cta.button')}
              </Link>
            </motion.div>
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
} 