import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Calculate } from '@mui/icons-material';

export default function CalculatorLanding() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          <div className="flex items-center justify-center gap-3 mb-8">
            <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-red-600">
              <Calculate className="h-6 w-6 text-white" />
            </div>
          </div>
          <h1 className="text-4xl font-bold text-slate-800 mb-4">
            Financial Calculators
          </h1>
          <p className="text-lg text-slate-600 max-w-2xl mx-auto">
            Accurate, up-to-date financial calculators to help you make informed decisions
            about your finances in Kenya.
          </p>
        </motion.div>

        <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
          >
            <Link
              to="/calculators/paye"
              className="block group relative rounded-xl border border-slate-200 bg-white p-6 hover:shadow-lg transition-all"
            >
              <div className="flex items-center gap-4">
                <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-red-600/10 group-hover:bg-red-600">
                  <Calculate className="h-6 w-6 text-red-600 group-hover:text-white" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-slate-800">PAYE Calculator</h3>
                  <p className="mt-2 text-sm text-slate-600">
                    Calculate your Pay As You Earn tax based on the latest KRA rates
                  </p>
                </div>
              </div>
            </Link>
          </motion.div>

          {/* Add more calculator cards here in the future */}
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="mt-16 bg-slate-50 rounded-xl p-8"
        >
          <h2 className="text-2xl font-bold text-slate-800 mb-4">
            Why Use Our Calculators?
          </h2>
          <div className="grid gap-6 md:grid-cols-3">
            <div>
              <h3 className="font-semibold text-slate-800 mb-2">Accurate</h3>
              <p className="text-slate-600">
                Updated with the latest tax rates and regulations from KRA
              </p>
            </div>
            <div>
              <h3 className="font-semibold text-slate-800 mb-2">Easy to Use</h3>
              <p className="text-slate-600">
                Simple interface with clear instructions and instant results
              </p>
            </div>
            <div>
              <h3 className="font-semibold text-slate-800 mb-2">Free</h3>
              <p className="text-slate-600">
                All calculators are free to use with no hidden costs
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
} 