export interface InvoiceItem {
  description: string;
  quantity: number;
  rate: number;
  amount: number;
}

export interface Currency {
  code: string;
  symbol: string;
  name: string;
}

export interface InvoiceData {
  invoiceNumber: string;
  dateIssued: string;
  dateDue: string;
  clientName: string;
  clientEmail: string;
  clientAddress: string;
  freelancerName: string;
  freelancerEmail: string;
  freelancerAddress: string;
  items: InvoiceItem[];
  notes: string;
  terms: string;
  taxRate: number;
  isTaxInclusive: boolean;
  subtotal: number;
  tax: number;
  total: number;
  logo?: string;
  currency: Currency;
}

export const CURRENCIES: Currency[] = [
  { code: 'USD', symbol: '$', name: 'US Dollar' },
  { code: 'KES', symbol: 'KSh', name: 'Kenyan Shilling' },
  { code: 'NGN', symbol: '₦', name: 'Nigerian Naira' },
  { code: 'ZAR', symbol: 'R', name: 'South African Rand' },
  { code: 'GHS', symbol: 'GH₵', name: 'Ghanaian Cedi' },
  { code: 'UGX', symbol: 'USh', name: 'Ugandan Shilling' },
  { code: 'TZS', symbol: 'TSh', name: 'Tanzanian Shilling' },
  { code: 'RWF', symbol: 'RF', name: 'Rwandan Franc' },
  { code: 'ETB', symbol: 'Br', name: 'Ethiopian Birr' },
  { code: 'EUR', symbol: '€', name: 'Euro' },
  { code: 'GBP', symbol: '£', name: 'British Pound' },
]; 