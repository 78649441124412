import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { KeyboardArrowDown, Twitter } from '@mui/icons-material';
import Logo from './Logo';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from '../lib/i18n/TranslationContext';

export default function Header() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCalculatorsOpen, setIsCalculatorsOpen] = useState(false);
  const { t } = useTranslation();

  const tools = [
    { name: t('home.tools.contract'), path: '/contract' },
    { name: t('home.tools.invoice'), path: '/invoice' },
    { name: t('home.tools.proposal'), path: '/proposal' },
    { name: t('home.tools.receipt'), path: '/receipt' },
    { name: t('home.tools.quote'), path: '/quote' },
    { name: t('home.tools.sow'), path: '/sow' },
    { name: t('home.tools.brief'), path: '/brief' },
    { name: t('home.tools.nda'), path: '/nda' },
    { name: t('home.tools.timesheet'), path: '/timesheet' },
    { name: t('home.tools.expense'), path: '/expense' },
    { name: t('home.tools.status'), path: '/status' }
  ];

  const calculators = [
    { name: 'PAYE Calculator', path: '/calculators/paye' },
    // Add more calculators here as they are created
  ];

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-md border-b border-slate-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/" className="flex items-center gap-2">
            <Logo />
            <span className="font-semibold text-slate-900">Lipana</span>
          </Link>

          {/* Center Navigation */}
          <div className="flex items-center gap-6">
            {/* Tools Dropdown */}
            <div className="relative">
              <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="flex items-center gap-1 text-sm text-slate-600 hover:text-slate-900 px-4 py-2 rounded-lg hover:bg-slate-50"
              >
                Tools
                <KeyboardArrowDown className={`w-5 h-5 transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`} />
              </button>

              {isDropdownOpen && (
                <div 
                  className="absolute right-0 mt-2 w-64 bg-white rounded-xl shadow-lg border border-slate-200 py-2"
                  onMouseLeave={() => setIsDropdownOpen(false)}
                >
                  {tools.map((tool) => (
                    <Link
                      key={tool.path}
                      to={tool.path}
                      className="block px-4 py-2 text-sm text-slate-600 hover:bg-slate-50 hover:text-slate-900"
                    >
                      {tool.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            {/* Calculators Dropdown */}
            <div className="relative">
              <button
                onClick={() => setIsCalculatorsOpen(!isCalculatorsOpen)}
                className="flex items-center gap-1 text-sm text-slate-600 hover:text-slate-900 px-4 py-2 rounded-lg hover:bg-slate-50"
              >
                Calculators
                <KeyboardArrowDown className={`w-5 h-5 transition-transform ${isCalculatorsOpen ? 'rotate-180' : ''}`} />
              </button>

              {isCalculatorsOpen && (
                <div 
                  className="absolute right-0 mt-2 w-64 bg-white rounded-xl shadow-lg border border-slate-200 py-2"
                  onMouseLeave={() => setIsCalculatorsOpen(false)}
                >
                  {calculators.map((calculator) => (
                    <Link
                      key={calculator.path}
                      to={calculator.path}
                      className="block px-4 py-2 text-sm text-slate-600 hover:bg-slate-50 hover:text-slate-900"
                    >
                      {calculator.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            {/* AI Link */}
            <Link
              to="/ai"
              className="text-sm text-slate-600 hover:text-slate-900 px-4 py-2 rounded-lg hover:bg-slate-50"
            >
              AI
            </Link>

            {/* About Link */}
            <Link
              to="/about"
              className="text-sm text-slate-600 hover:text-slate-900 px-4 py-2 rounded-lg hover:bg-slate-50"
            >
              {t('home.nav.about')}
            </Link>
          </div>

          {/* Right Side - Language Switcher and Twitter */}
          <div className="flex items-center gap-4">
            <LanguageSwitcher />
            <a
              href="https://twitter.com/lipanaApp"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 px-4 py-2 text-sm bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              <Twitter className="w-4 h-4" />
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </header>
  );
} 
