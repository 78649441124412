import React from 'react';
import { motion } from 'framer-motion';
import { 
  Description, 
  ReceiptLong, 
  PieChart,
  CheckCircle,
  Inventory,
  Warning,
  Search,
  Key,
  AccessTime,
  Receipt,
  Assessment
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTranslation } from '../lib/i18n/TranslationContext';
import Header from '../components/Header';
import Footer from '../components/Footer';

const apps = [
  {
    titleKey: 'home.tools.contract',
    descriptionKey: 'home.tools.contractDesc',
    icon: Description,
    path: '/contract',
    color: 'bg-slate-800'
  },
  {
    titleKey: 'home.tools.invoice',
    descriptionKey: 'home.tools.invoiceDesc',
    icon: ReceiptLong,
    path: '/invoice',
    color: 'bg-emerald-600'
  },
  {
    titleKey: 'home.tools.proposal',
    descriptionKey: 'home.tools.proposalDesc',
    icon: PieChart,
    path: '/proposal',
    color: 'bg-blue-600'
  },
  {
    titleKey: 'home.tools.receipt',
    descriptionKey: 'home.tools.receiptDesc',
    icon: CheckCircle,
    path: '/receipt',
    color: 'bg-purple-600'
  },
  {
    titleKey: 'home.tools.quote',
    descriptionKey: 'home.tools.quoteDesc',
    icon: Inventory,
    path: '/quote',
    color: 'bg-orange-600'
  },
  {
    titleKey: 'home.tools.sow',
    descriptionKey: 'home.tools.sowDesc',
    icon: Warning,
    path: '/sow',
    color: 'bg-red-600'
  },
  {
    titleKey: 'home.tools.brief',
    descriptionKey: 'home.tools.briefDesc',
    icon: Search,
    path: '/brief',
    color: 'bg-teal-600'
  },
  {
    titleKey: 'home.tools.nda',
    descriptionKey: 'home.tools.ndaDesc',
    icon: Key,
    path: '/nda',
    color: 'bg-indigo-600'
  },
  {
    titleKey: 'home.tools.timesheet',
    descriptionKey: 'home.tools.timesheetDesc',
    icon: AccessTime,
    path: '/timesheet',
    color: 'bg-pink-600'
  },
  {
    titleKey: 'home.tools.expense',
    descriptionKey: 'home.tools.expenseDesc',
    icon: Receipt,
    path: '/expense',
    color: 'bg-yellow-600'
  },
  {
    titleKey: 'home.tools.status',
    descriptionKey: 'home.tools.statusDesc',
    icon: Assessment,
    path: '/status',
    color: 'bg-cyan-600'
  }
];

export default function Home() {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      
      <main className="flex-grow bg-gradient-to-br from-slate-50 to-white px-8 pt-24 pb-12">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-16"
          >
            <h1 className="text-2xl font-bold text-slate-800 mb-4">
              {t('home.title')}
            </h1>
            <p className="text-slate-600">
              {t('home.description')}
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {apps.map((app, index) => (
              <motion.div
                key={app.path}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <Link
                  to={app.path}
                  className="block group"
                >
                  <div className="bg-white rounded-xl shadow-xl p-8 transition-transform duration-200 group-hover:-translate-y-1">
                    <div className={`${app.color} w-12 h-12 rounded-lg flex items-center justify-center mb-6`}>
                      <app.icon className="w-6 h-6 text-white" />
                    </div>
                    <h2 className="text-xl font-semibold text-slate-800 mb-2">
                      {t(app.titleKey)}
                    </h2>
                    <p className="text-slate-600">
                      {t(app.descriptionKey)}
                    </p>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
} 