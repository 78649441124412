import React from 'react';
import { InvoiceData, InvoiceItem, Currency, CURRENCIES } from '../types/invoice';
import { motion } from 'framer-motion';
import { Add, Delete, CloudUpload, Close } from '@mui/icons-material';

interface InvoiceFormProps {
  formData: InvoiceData;
  setFormData: React.Dispatch<React.SetStateAction<InvoiceData>>;
  onSubmit: (e: React.FormEvent) => void;
}

export default function InvoiceForm({ formData, setFormData, onSubmit }: InvoiceFormProps) {
  const calculateTotals = (items: InvoiceItem[], taxRate: number, isTaxInclusive: boolean) => {
    const subtotal = items.reduce((sum, item) => sum + (Number(item.quantity) * Number(item.rate)), 0);
    let taxAmount, total;

    if (isTaxInclusive) {
      taxAmount = subtotal - (subtotal / (1 + taxRate / 100));
      total = subtotal;
    } else {
      taxAmount = subtotal * (taxRate / 100);
      total = subtotal + taxAmount;
    }

    return { subtotal, tax: taxAmount, total };
  };

  const handleItemChange = (index: number, field: keyof InvoiceItem, value: string | number) => {
    const newItems = [...formData.items];
    newItems[index] = {
      ...newItems[index],
      [field]: value,
      amount: field === 'quantity' || field === 'rate' 
        ? Number(field === 'quantity' ? value : newItems[index].quantity) * 
          Number(field === 'rate' ? value : newItems[index].rate)
        : newItems[index].amount
    };

    // Calculate new totals
    const { subtotal, tax, total } = calculateTotals(
      newItems,
      formData.taxRate,
      formData.isTaxInclusive
    );

    setFormData(prev => ({
      ...prev,
      items: newItems,
      subtotal,
      tax,
      total
    }));
  };

  const handleAddItem = () => {
    const newItems = [
      ...formData.items,
      { description: '', quantity: 1, rate: 0, amount: 0 }
    ];

    // Calculate new totals after adding item
    const { subtotal, tax, total } = calculateTotals(
      newItems,
      formData.taxRate,
      formData.isTaxInclusive
    );

    setFormData(prev => ({
      ...prev,
      items: newItems,
      subtotal,
      tax,
      total
    }));
  };

  const handleRemoveItem = (index: number) => {
    const newItems = formData.items.filter((_, i) => i !== index);

    // Calculate new totals after removing item
    const { subtotal, tax, total } = calculateTotals(
      newItems,
      formData.taxRate,
      formData.isTaxInclusive
    );

    setFormData(prev => ({
      ...prev,
      items: newItems,
      subtotal,
      tax,
      total
    }));
  };

  const handleTaxChange = (field: 'taxRate' | 'isTaxInclusive', value: number | boolean) => {
    const { subtotal, tax, total } = calculateTotals(
      formData.items,
      field === 'taxRate' ? Number(value) : formData.taxRate,
      field === 'isTaxInclusive' ? Boolean(value) : formData.isTaxInclusive
    );

    setFormData(prev => ({
      ...prev,
      [field]: value,
      subtotal,
      tax,
      total
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 500000) { // 500KB limit
        alert('File size should be less than 500KB');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          logo: reader.result as string
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const removeLogo = () => {
    setFormData(prev => ({
      ...prev,
      logo: undefined
    }));
  };

  return (
    <form onSubmit={onSubmit} className="space-y-8">
      <div className="grid grid-cols-2 gap-6">
        {/* Freelancer Details */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-slate-800">Your Details</h3>
          
          {/* Logo Upload */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Brand Logo (optional)
            </label>
            {formData.logo ? (
              <div className="relative w-40 h-20 mb-2">
                <img
                  src={formData.logo}
                  alt="Brand logo"
                  className="w-full h-full object-contain"
                />
                <button
                  type="button"
                  onClick={removeLogo}
                  className="absolute -top-2 -right-2 p-1 bg-red-100 rounded-full text-red-500 hover:bg-red-200"
                >
                  <Close className="w-4 h-4" />
                </button>
              </div>
            ) : (
              <div className="flex items-center justify-center w-full">
                <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-slate-500 rounded-lg border-2 border-dashed border-slate-200 cursor-pointer hover:bg-slate-50">
                  <CloudUpload className="w-8 h-8 mb-2" />
                  <span className="text-sm">Upload logo (max 500KB)</span>
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={handleLogoUpload}
                  />
                </label>
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Name
            </label>
            <input
              type="text"
              name="freelancerName"
              value={formData.freelancerName}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email
            </label>
            <input
              type="email"
              name="freelancerEmail"
              value={formData.freelancerEmail}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Address
            </label>
            <textarea
              name="freelancerAddress"
              value={formData.freelancerAddress}
              onChange={handleChange}
              rows={3}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>

        {/* Client Details */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-slate-800">Client Details</h3>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Name
            </label>
            <input
              type="text"
              name="clientName"
              value={formData.clientName}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email
            </label>
            <input
              type="email"
              name="clientEmail"
              value={formData.clientEmail}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Address
            </label>
            <textarea
              name="clientAddress"
              value={formData.clientAddress}
              onChange={handleChange}
              rows={3}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>
      </div>

      {/* Invoice Details */}
      <div className="grid grid-cols-4 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Invoice Number
          </label>
          <input
            type="text"
            name="invoiceNumber"
            value={formData.invoiceNumber}
            onChange={handleChange}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Currency
          </label>
          <select
            name="currency"
            value={formData.currency.code}
            onChange={(e) => {
              const selectedCurrency = CURRENCIES.find(curr => curr.code === e.target.value);
              if (selectedCurrency) {
                setFormData(prev => ({
                  ...prev,
                  currency: selectedCurrency
                }));
              }
            }}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          >
            {CURRENCIES.map((currency) => (
              <option key={currency.code} value={currency.code}>
                {currency.code} - {currency.name} ({currency.symbol})
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Date Issued
          </label>
          <input
            type="date"
            name="dateIssued"
            value={formData.dateIssued}
            onChange={handleChange}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Due Date
          </label>
          <input
            type="date"
            name="dateDue"
            value={formData.dateDue}
            onChange={handleChange}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          />
        </div>
      </div>

      {/* Invoice Items */}
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold text-slate-800">Items</h3>
          <button
            type="button"
            onClick={handleAddItem}
            className="flex items-center gap-2 px-4 py-2 text-sm text-slate-800 hover:bg-slate-100 rounded-lg transition-colors"
          >
            <Add className="w-4 h-4" />
            Add Item
          </button>
        </div>
        
        <div className="space-y-4">
          {formData.items.map((item, index) => (
            <div key={index} className="grid grid-cols-12 gap-4 items-start">
              <div className="col-span-5">
                <input
                  type="text"
                  placeholder="Description"
                  value={item.description}
                  onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div className="col-span-2">
                <input
                  type="number"
                  placeholder="Quantity"
                  value={item.quantity}
                  onChange={(e) => handleItemChange(index, 'quantity', Number(e.target.value))}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div className="col-span-2">
                <input
                  type="number"
                  placeholder="Rate"
                  value={item.rate}
                  onChange={(e) => handleItemChange(index, 'rate', Number(e.target.value))}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div className="col-span-2">
                <input
                  type="number"
                  placeholder="Amount"
                  value={item.amount}
                  disabled
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 bg-gray-50"
                />
              </div>
              <div className="col-span-1">
                <button
                  type="button"
                  onClick={() => handleRemoveItem(index)}
                  className="p-3 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
                >
                  <Delete className="w-4 h-4" />
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end">
          <div className="w-80 space-y-4">
            <div className="flex justify-between">
              <span className="text-gray-600">Subtotal</span>
              <span className="font-medium">{formData.currency.symbol}{formData.subtotal.toFixed(2)}</span>
            </div>

            {/* Tax Controls */}
            <div className="space-y-3 border-t pt-3">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="enableTax"
                  checked={formData.taxRate > 0}
                  onChange={(e) => {
                    setFormData(prev => ({
                      ...prev,
                      taxRate: e.target.checked ? 10 : 0 // Default to 10% when enabled
                    }));
                    handleTaxChange('taxRate', e.target.checked ? 10 : 0);
                  }}
                  className="rounded border-gray-300 text-slate-800 focus:ring-slate-800"
                />
                <label htmlFor="enableTax" className="text-sm text-gray-600">
                  Enable Tax
                </label>
              </div>

              {formData.taxRate > 0 && (
                <div className="space-y-3">
                  <div className="flex items-center gap-4">
                    <input
                      type="number"
                      value={formData.taxRate}
                      onChange={(e) => handleTaxChange('taxRate', Number(e.target.value))}
                      min="0"
                      max="100"
                      step="0.1"
                      className="w-20 rounded-lg border border-gray-300 px-2 py-1 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                    />
                    <span className="text-sm text-gray-600">%</span>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      id="taxInclusive"
                      checked={formData.isTaxInclusive}
                      onChange={(e) => handleTaxChange('isTaxInclusive', e.target.checked)}
                      className="rounded border-gray-300 text-slate-800 focus:ring-slate-800"
                    />
                    <label htmlFor="taxInclusive" className="text-sm text-gray-600">
                      Tax Inclusive
                    </label>
                  </div>

                  <div className="flex justify-between text-gray-600">
                    <span>Tax Amount</span>
                    <span>{formData.currency.symbol}{formData.tax.toFixed(2)}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="flex justify-between border-t border-slate-200 pt-3 text-lg font-semibold">
              <span>Total</span>
              <span>{formData.currency.symbol}{formData.total.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Notes & Terms */}
      <div className="grid grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Notes
          </label>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            rows={4}
            placeholder="Any additional notes..."
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Terms & Conditions
          </label>
          <textarea
            name="terms"
            value={formData.terms}
            onChange={handleChange}
            rows={4}
            placeholder="Invoice terms and conditions..."
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          />
        </div>
      </div>

      <div className="pt-6">
        <button
          type="submit"
          className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-slate-800 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 transition-all duration-200"
        >
          Preview Invoice
        </button>
      </div>
    </form>
  );
} 