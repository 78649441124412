import React from 'react'

function Footer() {
  return (
    <footer className="bg-gradient-to-br from-violet-900 to-indigo-900 text-white">
      <div className="relative overflow-hidden">
        <div className="absolute inset-0">
          <div 
            className="absolute inset-0 bg-[url(/grid.svg)] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]" 
            style={{ 
              backgroundSize: '30px 30px',
              opacity: 0.2,
              maskImage: 'linear-gradient(to bottom, white, transparent)'
            }} 
          />
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="mt-8 text-center">
            <h3 className="text-xl font-semibold mb-4 text-white">Ready to transform your business?</h3>
            <a
              href="https://wo1rar62if6.typeform.com/to/Dwc8VtGy"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 px-6 py-3 bg-violet-600 text-white rounded-lg font-semibold hover:bg-violet-700 transition-colors"
            >
              Get Started Now
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer 