import React, { useState, useRef, useEffect } from 'react';
import { type Locale } from '../lib/i18n/translations';
import { useTranslation } from '../lib/i18n/TranslationContext';
import { KeyboardArrowDown } from '@mui/icons-material';
import FlagIcon from './icons/FlagIcon';

const languageNames: Record<Locale, string> = {
  en: 'English',
  es: 'Español',
  fr: 'Français'
};

export default function LanguageSwitcher() {
  const { locale, setLocale } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 text-sm text-slate-600 hover:text-slate-900 px-4 py-2 rounded-lg hover:bg-slate-50"
      >
        <FlagIcon locale={locale} />
        <span>{languageNames[locale]}</span>
        <KeyboardArrowDown 
          className={`w-5 h-5 transition-transform ${isOpen ? 'rotate-180' : ''}`}
        />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-xl shadow-lg border border-slate-200 py-2 z-50">
          {Object.keys(languageNames).map((lang) => (
            <button
              key={lang}
              onClick={() => {
                setLocale(lang as Locale);
                setIsOpen(false);
              }}
              className={`w-full flex items-center gap-3 px-4 py-2 text-sm text-left hover:bg-slate-50 
                ${locale === lang ? 'bg-slate-100 text-slate-900' : 'text-slate-600 hover:text-slate-900'}`}
            >
              <FlagIcon locale={lang as Locale} />
              <span>{languageNames[lang as Locale]}</span>
              {locale === lang && (
                <span className="ml-auto text-blue-500 text-xs">✓</span>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
} 